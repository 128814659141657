import React, { useEffect } from "react";
import styled from "styled-components";
import AboutHero from "./Hero";
import AboutFounderJourney from "./FounderJourney";
import AboutProblemSolution from "./ProblemSolution";
import AboutAIExplanation from "./AIExplanation";
import AboutImpact from "./Impact";
import AboutCTA from "./CTA";

const AboutContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const SectionSpacer = styled.div`
  height: 20px;
`;

const About: React.FC = () => {
  useEffect(() => {
    document.title = "GrocerGrowth - About";

    // Optional: Reset the title when the component unmounts
    return () => {
      document.title = "GrocerGrowth"; // Or whatever your default title should be
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <>
      <AboutHero />
      <AboutContainer>
        <SectionSpacer />
        <AboutFounderJourney />
        <SectionSpacer />
        <AboutProblemSolution />
        <SectionSpacer />
        <AboutAIExplanation />
        <SectionSpacer />
        <AboutImpact />
        <SectionSpacer />
        <SectionSpacer />
      </AboutContainer>
      <AboutCTA />
    </>
  );
};

export default About;
