import React from "react";
import styled from "styled-components";

const ContactContainer = styled.div`
  padding: 2rem;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const TextArea = styled.textarea`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
`;

const Contact: React.FC = () => {
  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <ContactForm>
        <Input type="text" placeholder="Name" required />
        <Input type="email" placeholder="Email" required />
        <TextArea placeholder="Message" required />
        <SubmitButton type="submit">Send</SubmitButton>
      </ContactForm>
    </ContactContainer>
  );
};

export default Contact;
