import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";
import { debounce } from "lodash";

const SliderContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 800px;
  margin: 1rem auto;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;

const Title = styled.h2`
  color: #4a4a4a;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const SliderGroup = styled.div`
  margin-bottom: 1rem;
`;

const SliderLabel = styled.label`
  display: block;
  margin-bottom: 0.25rem;
  font-weight: bold;
  font-size: 0.9rem;
`;

const SliderInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 0.625rem;
  border-radius: 0.3125rem;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
`;

const SliderValue = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-weight: bold;
  font-size: 0.9rem;
`;

const ProjectedSavings = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #4caf50;
  margin-top: 1.5rem;
  padding: 0.75rem;
  background-color: #e8f5e9;
  border-radius: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoBox = styled.div`
  background-color: #e8f5e9;
  padding: 1rem;
  border-radius: 5px;
  flex: 1;
  min-width: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface SliderData {
  stores: number;
  revenuePerStore: number;
  preparedFoodsProportion: number;
  preparedFoodsWastage: number;
  nonPreparedFoodsWastage: number;
}

type SliderName = keyof SliderData;

const calculatePotentialReduction = (
  initialWaste: number,
  isPrepared: boolean
): number => {
  if (initialWaste <= 0) return 0;

  const baseImprovement = isPrepared ? 0.25 : 0.15;
  const maxImprovement = isPrepared ? 0.35 : 0.2;
  const optimalWasteLevel = isPrepared ? 40 : 20;

  const improvementFactor = Math.min(initialWaste / optimalWasteLevel, 1);
  const improvement =
    baseImprovement + (maxImprovement - baseImprovement) * improvementFactor;

  const reducedWaste = initialWaste * (1 - improvement);
  return Math.round(reducedWaste * 100) / 100;
};

const BenefitsSlider: React.FC = () => {
  const [sliderData, setSliderData] = useState<SliderData>({
    stores: 15,
    revenuePerStore: 25,
    preparedFoodsProportion: 30,
    preparedFoodsWastage: 35,
    nonPreparedFoodsWastage: 15,
  });

  const [isDragging, setIsDragging] = useState(false);
  const [activeSliderName, setActiveSliderName] = useState<SliderName | null>(
    null
  );
  const sliderRefs = useRef<Record<SliderName, HTMLInputElement | null>>(
    {} as Record<SliderName, HTMLInputElement | null>
  );

  const updateSliderValue = useCallback((name: SliderName, value: number) => {
    setSliderData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleSliderChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, valueAsNumber } = e.target;
      updateSliderValue(name as SliderName, valueAsNumber);
    },
    [updateSliderValue]
  );

  const calculateNewValue = useCallback(
    (sliderName: SliderName, pageX: number) => {
      const slider = sliderRefs.current[sliderName];
      if (!slider) return null;

      const { left, width } = slider.getBoundingClientRect();
      const min = parseFloat(slider.min);
      const max = parseFloat(slider.max);
      const step = parseFloat(slider.step);
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft;

      if (width === 0) {
        console.error(
          `Slider ${sliderName} width is 0. Unable to calculate new value.`
        );
        return null;
      }

      const percentage = Math.max(
        0,
        Math.min(1, (pageX - (left + scrollX)) / width)
      );
      let newValue = min + percentage * (max - min);
      newValue = Math.round(newValue / step) * step;
      newValue = Math.max(min, Math.min(max, newValue));

      return newValue;
    },
    []
  );

  const handleSliderDrag = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDragging || !activeSliderName) return;

      let pageX: number;
      if (e instanceof MouseEvent) {
        pageX = e.pageX;
      } else if (e instanceof TouchEvent && e.touches.length > 0) {
        pageX = e.touches[0].pageX;
      } else {
        return;
      }

      const newValue = calculateNewValue(activeSliderName, pageX);
      if (newValue !== null) {
        updateSliderValue(activeSliderName, newValue);
      }
    },
    [isDragging, activeSliderName, calculateNewValue, updateSliderValue]
  );

  const debouncedHandleSliderDrag = useMemo(
    () => debounce(handleSliderDrag, 10),
    [handleSliderDrag]
  );

  const handleSliderStart = useCallback(
    (
      e: React.MouseEvent<HTMLInputElement> | React.TouchEvent<HTMLInputElement>
    ) => {
      const slider = e.currentTarget;
      setIsDragging(true);
      setActiveSliderName(slider.name as SliderName);
    },
    []
  );

  const handleSliderEnd = useCallback(() => {
    setIsDragging(false);
    setActiveSliderName(null);
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => debouncedHandleSliderDrag(e);
    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
      debouncedHandleSliderDrag(e);
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      document.addEventListener("mouseup", handleSliderEnd);
      document.addEventListener("touchend", handleSliderEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("mouseup", handleSliderEnd);
      document.removeEventListener("touchend", handleSliderEnd);
      debouncedHandleSliderDrag.cancel();
    };
  }, [isDragging, debouncedHandleSliderDrag, handleSliderEnd]);

  const preparedFoodsReduction = useMemo(
    () => calculatePotentialReduction(sliderData.preparedFoodsWastage, true),
    [sliderData.preparedFoodsWastage]
  );

  const nonPreparedFoodsReduction = useMemo(
    () =>
      calculatePotentialReduction(sliderData.nonPreparedFoodsWastage, false),
    [sliderData.nonPreparedFoodsWastage]
  );

  const totalRevenue = useMemo(
    () => sliderData.stores * sliderData.revenuePerStore * 1000000,
    [sliderData.stores, sliderData.revenuePerStore]
  );

  const preparedFoodsRevenue = useMemo(
    () => totalRevenue * (sliderData.preparedFoodsProportion / 100),
    [totalRevenue, sliderData.preparedFoodsProportion]
  );

  const nonPreparedFoodsRevenue = useMemo(
    () => totalRevenue - preparedFoodsRevenue,
    [totalRevenue, preparedFoodsRevenue]
  );

  const totalSavings = useMemo(() => {
    const preparedFoodsSavings =
      (preparedFoodsRevenue *
        (sliderData.preparedFoodsWastage - preparedFoodsReduction)) /
      100;
    const nonPreparedFoodsSavings =
      (nonPreparedFoodsRevenue *
        (sliderData.nonPreparedFoodsWastage - nonPreparedFoodsReduction)) /
      100;
    return preparedFoodsSavings + nonPreparedFoodsSavings;
  }, [
    preparedFoodsRevenue,
    nonPreparedFoodsRevenue,
    sliderData.preparedFoodsWastage,
    sliderData.nonPreparedFoodsWastage,
    preparedFoodsReduction,
    nonPreparedFoodsReduction,
  ]);

  const Slider: React.FC<{
    name: SliderName;
    label: string;
    min: number;
    max: number;
    step: number;
  }> = ({ name, label, min, max, step }) => (
    <SliderGroup>
      <SliderLabel htmlFor={name}>{label}</SliderLabel>
      <SliderInput
        id={name}
        type="range"
        min={min}
        max={max}
        step={step}
        name={name}
        value={sliderData[name]}
        onChange={handleSliderChange}
        onMouseDown={handleSliderStart}
        onTouchStart={handleSliderStart}
        ref={(el) => {
          sliderRefs.current[name] = el;
        }}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={sliderData[name]}
        aria-label={label}
      />
      <SliderValue>
        {name === "revenuePerStore"
          ? `$${sliderData[name]}M`
          : `${sliderData[name]}${
              name.includes("Proportion") || name.includes("Wastage") ? "%" : ""
            }`}
      </SliderValue>
    </SliderGroup>
  );

  return (
    <SliderContainer>
      <Title>
        Estimate Your Potential Savings by Dragging the Sliders. Under the
        Sliders is Your Estimated Savings.
      </Title>
      <Slider
        name="stores"
        label="Number of Stores"
        min={1}
        max={100}
        step={1}
      />
      <Slider
        name="revenuePerStore"
        label="Revenue Per Store (in millions)"
        min={1}
        max={50}
        step={0.1}
      />
      <Slider
        name="preparedFoodsProportion"
        label="Prepared Foods Proportion (%)"
        min={0}
        max={100}
        step={1}
      />
      <Slider
        name="preparedFoodsWastage"
        label="Prepared Foods Wastage (%)"
        min={0}
        max={100}
        step={1}
      />
      <Slider
        name="nonPreparedFoodsWastage"
        label="Non-Prepared Foods Wastage (%)"
        min={0}
        max={100}
        step={1}
      />
      <InfoContainer>
        <InfoBox>
          <h3>Prepared Foods</h3>
          <p>Current wastage: {sliderData.preparedFoodsWastage}%</p>
          <p>Estimated wastage after reduction: {preparedFoodsReduction}%</p>
          <p>
            Improvement:{" "}
            {(
              ((sliderData.preparedFoodsWastage - preparedFoodsReduction) /
                sliderData.preparedFoodsWastage) *
              100
            ).toFixed(2)}
            %
          </p>
        </InfoBox>
        <InfoBox>
          <h3>Non-Prepared Foods</h3>
          <p>Current wastage: {sliderData.nonPreparedFoodsWastage}%</p>
          <p>Estimated wastage after reduction: {nonPreparedFoodsReduction}%</p>
          <p>
            Improvement:{" "}
            {(
              ((sliderData.nonPreparedFoodsWastage -
                nonPreparedFoodsReduction) /
                sliderData.nonPreparedFoodsWastage) *
              100
            ).toFixed(2)}
            %
          </p>
        </InfoBox>
      </InfoContainer>
      <ProjectedSavings>
        Estimated Annual Savings: $
        {totalSavings.toLocaleString(undefined, { maximumFractionDigits: 0 })}
      </ProjectedSavings>
    </SliderContainer>
  );
};

export default BenefitsSlider;
