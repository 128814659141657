import React from "react";
import styled from "styled-components";
import {
  FaGraduationCap,
  FaLightbulb,
  FaRocket,
  FaLaptopCode,
} from "react-icons/fa";
import { MdSurfing } from "react-icons/md";

import { GoLaw } from "react-icons/go";

const JourneyContainer = styled.section`
  padding: 10px 0;
  background-color: ${(props) => props.theme.colors.lightGray};
`;

const JourneyTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const TimelineItem = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
`;

const TimelineIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
`;

const TimelineContent = styled.div`
  flex: 1;
  padding: 0px 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const TimelineTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const TimelineDescription = styled.p`
  font-size: 1rem;
  line-height: 1.4;
  font-family: ${(props) => props.theme.fonts.main};
`;

const AboutFounderJourney: React.FC = () => {
  const journeySteps = [
    {
      icon: <MdSurfing />,
      title: "Founder & CEO: Alexander Stern",
      description:
        "From a young age, Alex loved the beach and ocean. He grew up in the Los Angeles area, and communing with nature was a daily passion. There is something deeply meditative about surfing a wave and letting nature take you along for the ride. However, it became clear that technology was creating unprecedented risks to the environment. Alex also loved technology, and thought there must be a better way to use it for good.",
    },
    {
      icon: <FaLaptopCode />,
      title: "Alex's First Software: A Donation and Volunteer Platform",
      description:
        "In high school, Alex taught himself computer programming. He built a platform called GiveDontPay. This was a way for people to volunteer to watch ads not for the benefit of corporations, but instead for environmental and sustainability focused charities. Ads generate many billions of dollars, and directing a portion of this money to environmental nonprofits seemed like a huge untapped opportunity.",
    },
    {
      icon: <FaGraduationCap />,
      title: "Graduate School at UC Berkeley",
      description:
        "Alex knew he needed to learn more to do the most good. So, he became a student at UC Berkeley, where he was active in both the School of Law where he got his JD and the UC Berkeley School of Information. Alex focused on AI and technology while at Berkeley. He combined studies in Environmental Policy with machine learning. He developed techniques using state of the art machine learning strategies to track polluters. He earned High Honors in various relevant fields and graduated UC Berkeley near the top of his class.",
    },
    {
      icon: <FaLightbulb />,
      title:
        "The Epiphany: Collaboration with Industry was Far Better than Opposition",
      description:
        "While working on AI systems to track excessive pollution, Alex realized that helping industry improve would go much further than holding them accountable after the fact. One ounce of prevention is worth a pound of cure. Further, this dovetails with Alex's belief that businesses are actively a great benefit to society. Most people do not want to be polluters or otherwise harmful. They just need the tools to do the right thing.",
    },
    {
      icon: <GoLaw />,
      title: "Moving from Cure to Prevention",
      description:
        "Driven by this insight, Alex founded Attorney IO, an AI-powered platform providing top-tier compliance and legal advice to businesses. The goal was to help companies proactively address potential issues. This venture garnered attention from major press outlets and academia, with Alex frequently participating on panels alongside world-renowned experts and top professors.",
    },
    {
      icon: <FaRocket />,
      title: "Founding GrocerGrowth",
      description:
        "Alex recognized that focusing on incentives (profits) rather than disincentives (legal compliance) was a much better way to promote environmental stewardship. He envisioned a broader approach: aligning best practices in sustainability with huge financial incentives. This new mindset sparked the idea that would become GrocerGrowth—a venture aimed at making environmental responsibility not just legally compliant, but highly profitable, particularly in the grocery sector.",
    },
  ];

  return (
    <JourneyContainer>
      <JourneyTitle>The Journey to GrocerGrowth</JourneyTitle>
      <Timeline>
        {journeySteps.map((step, index) => (
          <TimelineItem key={index}>
            <TimelineIcon>{step.icon}</TimelineIcon>
            <TimelineContent>
              <TimelineTitle>{step.title}</TimelineTitle>
              <TimelineDescription>{step.description}</TimelineDescription>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </JourneyContainer>
  );
};

export default AboutFounderJourney;
