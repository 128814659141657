import React from "react";
import styled from "styled-components";
import { FaTrash, FaMoneyBillWave, FaLeaf } from "react-icons/fa";
import { CgMathPercent } from "react-icons/cg";

const SectionContainer = styled.section`
  padding: 10px 0;
  background-color: white;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const ProblemSolutionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ItemTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const ItemContent = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-family: ${(props) => props.theme.fonts.main};
`;

const StatList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const StatItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.fonts.main};
`;

const StatIcon = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.colors.primary};
`;

const AboutProblemSolution: React.FC = () => {
  return (
    <SectionContainer>
      <SectionTitle>The Problem and Our Solution</SectionTitle>
      <ProblemSolutionGrid>
        <GridItem>
          <ItemTitle>The Problem: Food Waste in Grocery Retail</ItemTitle>
          <ItemContent>
            According to the USDA, between "30-40 percent of the food supply" is
            lost due to waste. This translates to 133 billion pounds of food.
            This translates to an estimated $161 billion dollars of wastage
            every single year. This means every single second, about 4,217
            pounds is wasted and this costs more than $5,105. This is every
            single second of every single day.
          </ItemContent>
          <StatList>
            <StatItem>
              <StatIcon>
                <FaTrash />
              </StatIcon>
              133 billion pounds of food are lost to waste every single second.
            </StatItem>
            <StatItem>
              <StatIcon>
                <FaMoneyBillWave />
              </StatIcon>
              Food wastage costs businesses $5,105 every single second of the
              day.
            </StatItem>
            <StatItem>
              <StatIcon>
                <FaLeaf />
              </StatIcon>
              About 1/3 of all food produced globally goes to waste
            </StatItem>
          </StatList>
        </GridItem>
        <GridItem>
          <ItemTitle>Our Solution: AI-Powered Waste Reduction</ItemTitle>
          <ItemContent>
            The USDA says, "In 2015, the USDA joined with the U.S. Environmental
            Protection Agency to set a goal to cut our nation’s food waste by 50
            percent by the year 2030." This is seen by some grocers as a
            problem, but it's actual a great opportunity and beacon of hope. It
            says very tangible gains are not only possible, but attainable.
            Using state of the art statistical and mathematical models, grocers
            are finally able to adhere to this USDA and EPA target. And this
            isn't just about that -- this is a fantastic opportunity to deliver
            millions of dollars in gains to your grocery business.
          </ItemContent>
          <StatList>
            <StatItem>
              <StatIcon>
                <FaMoneyBillWave />
              </StatIcon>
              Increase profit margins by optimizing inventory
            </StatItem>
            <StatItem>
              <StatIcon>
                <FaLeaf />
              </StatIcon>
              Enhance sustainability credentials
            </StatItem>
            <StatItem>
              <StatIcon>
                <CgMathPercent />
              </StatIcon>
              Uses statistical and mathematical models that the best
              mathematicians (e.g. MIT PhDs in math) would otherwise have to
              spend full time doing for each store.
            </StatItem>
          </StatList>
        </GridItem>
      </ProblemSolutionGrid>
    </SectionContainer>
  );
};

export default AboutProblemSolution;
