import React from "react";
import styled from "styled-components";
import {
  FaPlug,
  FaBrain,
  FaChartLine,
  FaRobot,
  FaSyncAlt,
} from "react-icons/fa";

const Section = styled.section`
  padding: 0.3rem 2rem;
  background-color: ${(props) => props.theme.colors.lightGray};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.colors.primary};
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
`;

const TimelineItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
  align-items: flex-start;
`;

const TimelineIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
`;

const TimelineContent = styled.div`
  flex: 1;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const TimelineTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.primary};
`;

const TimelineDescription = styled.p`
  font-size: 1rem;
  line-height: 1.4;
`;

const HowItWorks: React.FC = () => {
  const steps = [
    {
      title: "Data Integration",
      description:
        "Every year you might collect the same type of data with your point of sale system. What you might not realize is that very recent advances in AI have made this data MUCH more valuable. We'll apply the best techniques from UC Berkeley to your data to make it reveal exactly how to best reduce food wastage. We factor variables such as: 🌦️ Weather patterns 🎭 Local events 📊 Historical sales data 🛒 Consumer behavior trends 🌎 Global supply chain fluctuations",
      icon: <FaPlug />,
    },
    {
      title: "AI Analysis",
      description:
        "Identify patterns and trends beyond human capability. This is a treasure map to potentially millions of dollars per year. Our AI will work day and night like a 24/7 statistics genius solely dedicated to saving your grocer as much money as possible.",
      icon: <FaBrain />,
    },
    {
      title: "We'll Show You How Much You Would Have Saved Historically",
      description:
        "We'll go back in time to particular dates in your store's history. We'll tell you what our AI would've suggested. We'll show you directly how much you would've saved if you had used our AI on that date.",
      icon: <FaChartLine />,
    },
    {
      title: "We're so confident, we'll take all the risk",
      description:
        "AI means we don't need to hire an extremely pricey 'quantitative analyst' to work full time for each different store. Using AI means the most advanced and previously time consuming statistical and machine learning tools are now available even to small and medium grocers. This also means we can give you 2 months completely free with absolutely no risk. This is getting all of the advantage of a UC Berkeley trained expert for 60 days at no charge. You only have upside.",
      icon: <FaRobot />,
    },
    {
      title: "Looking Forward",
      description:
        "Our goal is to make this the most win/win collaboration possible. Everything about our system, from our free onboarding to our subsequent pricing, is designed to make working together as easy and beneficial for our grocer customers as possible. Beyond that, we'll constantly work with your store's specific needs and apply the constantly evolving best practices in AI. Given the pace of AI (just look at Nvidia's stock price), we expect working together will only go from strength to strength.",
      icon: <FaSyncAlt />,
    },
  ];

  return (
    <Section>
      <SectionTitle>How GrocerGrowth Works</SectionTitle>
      <Timeline>
        {steps.map((step, index) => (
          <TimelineItem key={index}>
            <TimelineIcon>{step.icon}</TimelineIcon>
            <TimelineContent>
              <TimelineTitle>{step.title}</TimelineTitle>
              <TimelineDescription>{step.description}</TimelineDescription>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Section>
  );
};

export default HowItWorks;
