import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeroContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/hero-background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 1.4rem 1rem;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HeroTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const Hero: React.FC = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroTitle>
          Revolutionize Your Grocer's Profitability and Sustainability By
          Dramatically Reducing Food Wastage with AI
        </HeroTitle>
        <HeroSubtitle>
          Humans cannot possibly see the hidden patterns buried within your
          data. These patterns represent as much as millions of dollars that you
          are throwing away every year. Massive new changes to AI mean we can
          for the first time radically reduce food waste. We are so confident in
          our solution, we give it away completely free for 2 months. No
          contract, no obligation. We know you'll come back for more.
        </HeroSubtitle>
        <CTAButton to="/get-started">Get Started Free & Easy</CTAButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
