import React from "react";
import styled from "styled-components";

const GetStartedContainer = styled.div`
  padding: 2rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const EmailHighlight = styled.a`
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #f5f5f5;
  text-decoration: none;
  color: #4caf50;
  text-align: center;
  
  &:hover {
    background-color: #e8e8e8;
  }
`;

const Text = styled.p`
  margin-bottom: 1rem;
`;

const Contact: React.FC = () => {
  return (
    <GetStartedContainer>
      <ContentWrapper>
        <Text>
          We're here to help your grocery business thrive. For questions about reducing food waste, 
          optimizing inventory, or starting your free 2-month trial, reach out to our team:
        </Text>
        
        <EmailHighlight href="mailto:begin@grocergrowth.com">
          begin@grocergrowth.com
        </EmailHighlight>

        <Text>
          Our team typically responds within 1 business day. For the fastest assistance, 
          please include your store name and number of locations in your initial message.
        </Text>
        
        <Text>
          Looking for a quick estimate of potential savings? Try our calculator on the home page, 
          or email us your annual revenue and current food waste percentages for a custom analysis.
        </Text>
      </ContentWrapper>
    </GetStartedContainer>
  );
};

export default Contact;