import React from "react";
import Hero from "./Hero";
import ValueProposition from "./ValueProposition";
import HowItWorks from "./HowItWorks";
import CTA from "../../components/CTA";
import BenefitsSlider from "./BenefitsSlider";
import PressLogos from "./PressLogos";
import Vision from "./Vision";

const Home: React.FC = () => {
  return (
    <div>
      <Hero />
      <ValueProposition />
      <HowItWorks />
      <PressLogos />
      <BenefitsSlider />
      <Vision />
      <CTA />
    </div>
  );
};

export default Home;
