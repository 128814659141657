import React from "react";
import styled from "styled-components";
import { FaChartLine, FaRecycle, FaRobot, FaLeaf } from "react-icons/fa";

const SolutionContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const SolutionHeader = styled.header`
  text-align: center;
  margin-bottom: 4rem;
`;

const SolutionTitle = styled.h1`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const SolutionSubtitle = styled.p`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.text};
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

const FeatureCard = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
`;

const BenefitsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const BenefitItem = styled.li`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;

  &:before {
    content: "✓";
    color: ${(props) => props.theme.colors.primary};
    position: absolute;
    left: 0;
  }
`;

const CTASection = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 4rem 2rem;
  text-align: center;
  border-radius: 8px;
`;

const CTATitle = styled.h2`
  font-size: 2rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const CTADescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const Solution: React.FC = () => {
  return (
    <SolutionContainer>
      <SolutionHeader>
        <SolutionTitle>Our AI-Driven Waste Reduction Solution</SolutionTitle>
        <SolutionSubtitle>
          Revolutionize your grocery store with cutting-edge technology
        </SolutionSubtitle>
      </SolutionHeader>

      <SectionTitle>Key Features</SectionTitle>
      <FeatureGrid>
        <FeatureCard>
          <FeatureIcon>
            <FaChartLine />
          </FeatureIcon>
          <FeatureTitle>Advanced Demand Forecasting</FeatureTitle>
          <FeatureDescription>
            Predict customer demand with exceptional accuracy and granularity.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>
            <FaRobot />
          </FeatureIcon>
          <FeatureTitle>Intelligent Inventory Optimization</FeatureTitle>
          <FeatureDescription>
            Dynamically set reorder points, safety stock levels, and order
            quantities.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>
            <FaRecycle />
          </FeatureIcon>
          <FeatureTitle>Proactive Freshness Management</FeatureTitle>
          <FeatureDescription>
            Ensure every product on your shelves is at its peak of freshness and
            quality.
          </FeatureDescription>
        </FeatureCard>
        <FeatureCard>
          <FeatureIcon>
            <FaLeaf />
          </FeatureIcon>
          <FeatureTitle>Sustainability Tracking</FeatureTitle>
          <FeatureDescription>
            Monitor and report on your store's environmental impact and waste
            reduction efforts.
          </FeatureDescription>
        </FeatureCard>
      </FeatureGrid>

      <SectionTitle>Benefits</SectionTitle>
      <BenefitsList>
        <BenefitItem>
          Reduce prepared food waste from approximately 40% to 24-28%
        </BenefitItem>
        <BenefitItem>
          Decrease non-prepared food waste from approximately 15% to 12.75%
        </BenefitItem>
        <BenefitItem>
          Potential annual net benefit of $20.815 million to $30.981 million
        </BenefitItem>
        <BenefitItem>
          Projected 5-year cumulative net benefit of $88.658 million to $117.567
          million
        </BenefitItem>
        <BenefitItem>
          Enhance sustainability efforts and reduce environmental impact
        </BenefitItem>
        <BenefitItem>
          Improve customer satisfaction with better product availability
        </BenefitItem>
      </BenefitsList>

      <CTASection>
        <CTATitle>Ready to Transform Your Grocery Store?</CTATitle>
        <CTADescription>
          Experience the power of AI-driven waste reduction and profit
          maximization.
        </CTADescription>
        <CTAButton href="/get-started">Get Started Today</CTAButton>
      </CTASection>
    </SolutionContainer>
  );
};

export default Solution;
