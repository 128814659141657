import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CTAContainer = styled.section`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 20px 0;
  text-align: center;
`;

const CTAContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: ${(props) => props.theme.fonts.heading};
`;

const CTAText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-family: ${(props) => props.theme.fonts.main};
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: #de6843;
  color: white;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-family: ${(props) => props.theme.fonts.main};

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
    color: white;
    transform: translateY(-3px);
  }
`;

const AboutCTA: React.FC = () => {
  return (
    <CTAContainer>
      <CTAContent>
        <CTATitle>Ready to Revolutionize Your Grocery Business?</CTATitle>
        <CTAText>
          Join the AI-driven future of retail. Reduce waste, increase profits,
          and make a lasting impact on the environment. Let's transform the
          grocery industry together.
        </CTAText>
        <CTAButton to="/get-started">Get Started Free & Easy</CTAButton>
      </CTAContent>
    </CTAContainer>
  );
};

export default AboutCTA;
