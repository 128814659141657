import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CTASection = styled.section`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 20px 0;
  text-align: center;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const CTADescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled(Link)`
  background-color: #de6843;
  color: white;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const CTA: React.FC = () => {
  return (
    <CTASection>
      <CTATitle>
        Does a risk free way to save potentially millions annually, while also
        helping the planet and sustainability, sound good to you?
      </CTATitle>
      <CTADescription>
        Join the AI-driven future of retail. Reduce waste, increase profits, and
        delight your customers.
      </CTADescription>
      <CTAButton to="/get-started">Get Started Free & Easy</CTAButton>
    </CTASection>
  );
};

export default CTA;
