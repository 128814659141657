import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HeroContainer = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/about-hero-background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 30px 0;
  text-align: center;
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const HeroTitle = styled.h1`
  font-size: 2.2rem;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.fonts.heading};
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-family: ${(props) => props.theme.fonts.main};
`;

const CTAButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-family: ${(props) => props.theme.fonts.main};

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const AboutHero: React.FC = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroTitle>
          Behind the Scenes at GrocerGrowth: Bridging AI and Sustainability to
          Revolutionize Grocery Retail
        </HeroTitle>
        <HeroSubtitle>
          How we can leverage cutting-edge AI to combat food waste and transform
          the grocery industry
        </HeroSubtitle>
        <CTAButton to="/get-started">Get Started Free & Easy</CTAButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default AboutHero;
