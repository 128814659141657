import React from "react";
import styled from "styled-components";
import {
  FaShoppingCart,
  FaChartLine,
  FaUsers,
  FaCloudSun,
  FaCalendarAlt,
  FaDollarSign,
  FaHashtag,
  FaAppleAlt,
  FaStore,
  FaTruck,
  FaComments,
  FaClock,
  FaSearch,
  FaChartBar,
  FaRecycle,
  FaGlobeAmericas,
} from "react-icons/fa";

const SectionContainer = styled.section`
  padding: 0.1rem 0;
  background-color: ${(props) => props.theme.colors.lightGray};
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const AIExplanationContent = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 0.5rem;
`;

const ExplanationText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${(props) => props.theme.fonts.main};
`;

const FactorsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  justify-content: center;
`;

const FactorItem = styled.div`
  background-color: white;
  padding: 0.1rem;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  }
`;

const FactorTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FactorIcon = styled.span`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 0.3rem;
  font-size: 1.25rem;
`;

const FactorDescription = styled.p`
  font-size: 0.8rem;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.main};
`;

interface AIFactorProps {
  icon: React.ReactElement;
  title: string;
  description: string;
}

const AIFactor: React.FC<AIFactorProps> = ({ icon, title, description }) => (
  <FactorItem>
    <FactorTitle>
      <FactorIcon>{icon}</FactorIcon>
      {title}
    </FactorTitle>
    <FactorDescription>{description}</FactorDescription>
  </FactorItem>
);

const AboutAIExplanation: React.FC = () => {
  const aiFactors: AIFactorProps[] = [
    {
      icon: <FaShoppingCart />,
      title: "Sales Data",
      description: "Historical sales, trends, and patterns.",
    },
    {
      icon: <FaChartLine />,
      title: "Inventory Insights",
      description: "Stock levels, expiration dates, shelf life.",
    },
    {
      icon: <FaUsers />,
      title: "Demographics",
      description: "Age groups, income, dietary preferences.",
    },
    {
      icon: <FaCloudSun />,
      title: "Weather Impact",
      description: "Temperature, precipitation, extreme events.",
    },
    {
      icon: <FaCalendarAlt />,
      title: "Local Events",
      description: "Festivals, sports, concerts, school schedules.",
    },
    {
      icon: <FaDollarSign />,
      title: "Economic Indicators",
      description: "Unemployment, consumer confidence, inflation.",
    },
    {
      icon: <FaHashtag />,
      title: "Social Media",
      description: "Food-related hashtags and viral trends.",
    },
    {
      icon: <FaAppleAlt />,
      title: "Health & Nutrition",
      description: "Food safety, diets, health studies.",
    },
    {
      icon: <FaStore />,
      title: "Store Data",
      description: "Layout changes, promotions, staffing.",
    },
    {
      icon: <FaTruck />,
      title: "Supply Chain",
      description: "Delivery schedules, supplier issues.",
    },
    {
      icon: <FaComments />,
      title: "Customer Feedback",
      description: "Reviews, complaints, and returns.",
    },
    {
      icon: <FaClock />,
      title: "Time Patterns",
      description: "Day, time, and seasonal impacts.",
    },
    {
      icon: <FaSearch />,
      title: "Food Trends",
      description: "Search trends and viral content.",
    },
    {
      icon: <FaChartBar />,
      title: "Pricing",
      description: "Price changes and sales impact.",
    },
    {
      icon: <FaRecycle />,
      title: "Waste Reduction",
      description: "Tracking and analyzing waste causes.",
    },
    {
      icon: <FaGlobeAmericas />,
      title: "Geography",
      description: "Location-specific demand factors.",
    },
  ];

  return (
    <SectionContainer>
      <SectionTitle>How Our AI Works</SectionTitle>
      <AIExplanationContent>
        <ExplanationText>
          GrocerGrowth's AI is a comprehensive solution that thinks like a
          master grocer mixed with a mathematician. You can only make the best
          decisions if you have the best data. Here is the type of data we work
          with to give you the best suggestions:
        </ExplanationText>
        <FactorsGrid>
          {aiFactors.map((factor, index) => (
            <AIFactor key={index} {...factor} />
          ))}
        </FactorsGrid>
      </AIExplanationContent>
    </SectionContainer>
  );
};

export default AboutAIExplanation;
