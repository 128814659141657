import React from "react";
import styled from "styled-components";
import { FaChartLine, FaRecycle, FaUsers, FaLeaf } from "react-icons/fa";

const SectionContainer = styled.section`
  padding: 1px 0;
  background-color: white;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const ImpactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const ImpactCard = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ImpactIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 20px;
`;

const ImpactTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const ImpactDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  font-family: ${(props) => props.theme.fonts.main};
`;

// const ImpactMetric = styled.div`
//   font-size: 2.5rem;
//   font-weight: bold;
//   color: ${(props) => props.theme.colors.secondary};
//   margin: 20px 0;
//   font-family: ${(props) => props.theme.fonts.heading};
// `;

const AboutImpact: React.FC = () => {
  const impactData = [
    {
      icon: <FaChartLine />,
      title: "Increased Profitability",
      description:
        "Businesses exist to make profit. We want you to think of us not just as sustainability experts. Our explicit goal is to make your grocer much more profitable. We do this by eliminating one of the biggest inefficiencies grocers have.",
      // metric: "25%",
      // metricDescription: "average increase in profit margins",
    },
    {
      icon: <FaRecycle />,
      title: "Waste Reduction",
      description:
        "We help with your sustainability, which drives customer delight and PR. Unless you feel completely comfortable with how much food you are wasting, there is probably significant room for improvement.",
      // metric: "25-35%",
      // metricDescription: "reduction in food waste for partner stores",
    },
    {
      icon: <FaUsers />,
      title: "Customer Satisfaction",
      description:
        "Some solutions go so far into the direction of wastage reduction, they do not prioritize having the right amount of food for customers. We are the opposite. We know that the most important goal for your business is customer delight. We never prioritize wastage reduction over having enough food to serve your customers.",
      // metric: "92%",
      // metricDescription: "customer satisfaction rate",
    },
    {
      icon: <FaLeaf />,
      title: "Environmental Impact",
      description:
        "The USDA, EPA, and press are only going to get more stringent with mass environmental inefficiencies. We empower you to get ahead of bad press and regulatory action. By giving you all the tools to make sustainability easy and profitable, we keep you ahead of the curve.",
      // metric: "1M+",
      // metricDescription: "tons of CO2 emissions prevented",
    },
  ];

  return (
    <SectionContainer>
      <SectionTitle>How Our Team Drives Results for Your Team</SectionTitle>
      <ImpactGrid>
        {impactData.map((impact, index) => (
          <ImpactCard key={index}>
            <ImpactIcon>{impact.icon}</ImpactIcon>
            <ImpactTitle>{impact.title}</ImpactTitle>
            <ImpactDescription>{impact.description}</ImpactDescription>
            {/* <ImpactMetric>{impact.metric}</ImpactMetric>
            <ImpactDescription>{impact.metricDescription}</ImpactDescription> */}
          </ImpactCard>
        ))}
      </ImpactGrid>
    </SectionContainer>
  );
};

export default AboutImpact;
