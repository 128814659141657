import React from "react";
import styled from "styled-components";

const PressSection = styled.section`
  padding: 0rem 0;
  background-color: ${(props) => props.theme.colors.background};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.primary};
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`;

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

const PressLogos: React.FC = () => {
  return (
    <PressSection>
      <Container>
        <Title>Our Founding Team Has Been Featured In</Title>
        <LogoContainer>
          <LogoImage src="/press.jpg" alt="Press logos" />
        </LogoContainer>
      </Container>
    </PressSection>
  );
};

export default PressLogos;
