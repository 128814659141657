import React from "react";
import styled from "styled-components";
import { FaChartLine, FaRobot, FaMoneyBillWave } from "react-icons/fa";

const Section = styled.section`
  padding: 0.5rem 2rem;
  background-color: ${(props) => props.theme.colors.background};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.colors.primary};
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const FeatureItem = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
`;

const ValueProposition: React.FC = () => {
  return (
    <Section>
      <SectionTitle>
        UC Berkeley's Best AI Techniques + Your Grocer = Huge Savings{" "}
      </SectionTitle>
      <FeatureGrid>
        <FeatureItem>
          <FeatureIcon>
            <FaChartLine />
          </FeatureIcon>
          <FeatureTitle>
            Massively reduce one of the biggest problems for grocers today
          </FeatureTitle>
          <FeatureDescription>
            You probably know you're wasting a ton of food and money. This may
            be one of your biggest problems and obstacles to profitability. Up
            until very recently, humans were the best you could do in terms of
            food inventory prediction.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <FaRobot />
          </FeatureIcon>
          <FeatureTitle>
            Optimal AI techniques straight from UC Berkeley
          </FeatureTitle>
          <FeatureDescription>
            Our founder studied AI and environmental policy at UC Berkeley. He
            got honors for his work in AI. These techniques are so powerful,
            GrocerGrowth is happy to remove virtually all risk. Let us show you
            exactly how we could have saved you tons of money with a demo of our
            software.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <FeatureIcon>
            <FaMoneyBillWave />
          </FeatureIcon>
          <FeatureTitle>Increased Profitability</FeatureTitle>
          <FeatureDescription>
            Boost your bottom line through optimized inventory management and
            reduced operational costs. The newest methods can outcompete human
            food buyers so well, it could save a small to mid-sized chain
            without AI millions of dollars per year. We are so confident in a
            mutually beneficial relationship, we offer a completely free 2 month
            trial. We'll show you with your own data and our software exactly
            how much money you could've saved historically and into the future.
          </FeatureDescription>
        </FeatureItem>
      </FeatureGrid>
    </Section>
  );
};

export default ValueProposition;
