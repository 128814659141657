import React from "react";
import styled from "styled-components";
import { FaBolt, FaRocket, FaUserCheck, FaChartLine } from "react-icons/fa";

const SectionContainer = styled.section`
  padding: 1rem 0;
  background-color: ${(props) => props.theme.colors.lightGray};
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
`;

const VisionContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const VisionQuote = styled.blockquote`
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.main};
  text-align: center;
`;

const VisionText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-family: ${(props) => props.theme.fonts.main};
  text-align: center;
`;

const FutureApplications = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ApplicationCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ApplicationIcon = styled.div`
  font-size: 2.5rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
  text-align: center;
`;

const ApplicationTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.heading};
  text-align: center;
`;

const ApplicationDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  font-family: ${(props) => props.theme.fonts.main};
  text-align: center;
`;

const AboutVision: React.FC = () => {
  const futureApplications = [
    {
      icon: <FaBolt />,
      title: "Free to Start / No Obligation",
      description:
        "You can try our AI completely free for two months. We'll work with you to custom tailor our solution to best fit with your store's needs.",
    },
    {
      icon: <FaRocket />,
      title: "Quick Wins",
      description:
        "You should rapidly start to see the enormous monetary and sustainability benefits of working together.",
    },
    {
      icon: <FaUserCheck />,
      title: "Prioritizing Your Benefit",
      description:
        "During your completely risk free, no contract, and no obligation 2 month trial, we'll work with you to make a deal that you will be confident will be a great ROI.",
    },
    {
      icon: <FaChartLine />,
      title: "Concierge Support",
      description:
        "We'll always be there to take your calls, emails, and otherwise work with you to improve our service and help your grocer grow.",
    },
  ];

  return (
    <SectionContainer>
      <VisionContent>
        <SectionTitle>
          Our Vision: Making it The Biggest No-Brainer to Work with Our Team
        </SectionTitle>
        <VisionQuote>
          "Every single grocer should be able to be blown away by how much we
          can help them. I want to take every last particle of risk I can off of
          the table. I want to give grocers a completely free and concierge
          introduction to our solution. Once a grocer experiences reducing
          wastage this much, we'll work out the details to continue a profoundly
          mutually beneficial relationship." - Alex Stern, Founder
        </VisionQuote>
        <VisionText>
          To summarize, this is how our team can best serve your team:
        </VisionText>
        <FutureApplications>
          {futureApplications.map((app, index) => (
            <ApplicationCard key={index}>
              <ApplicationIcon>{app.icon}</ApplicationIcon>
              <ApplicationTitle>{app.title}</ApplicationTitle>
              <ApplicationDescription>{app.description}</ApplicationDescription>
            </ApplicationCard>
          ))}
        </FutureApplications>
      </VisionContent>
    </SectionContainer>
  );
};

export default AboutVision;
