export const theme = {
  colors: {
    primary: "#4CAF50",
    secondary: "#2196F3",
    background: "#FFFFFF",
    text: "#333333",
    lightGray: "#F5F5F5",
  },
  fonts: {
    main: "'Roboto', sans-serif",
    heading: "'Montserrat', sans-serif",
  },
  breakpoints: {
    mobile: "480px",
    tablet: "768px",
    desktop: "1024px",
  },
};
